export class UserProfile {

    id: string;
    username: string;
    email: string;
    firstName: string;
    lastName: string;    
    mobileNo: string; 
    gender : string;
   
  }
  