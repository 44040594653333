import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../services/token-storage.service';
import { UserService } from '../user/user.service';
import { UserProfile } from "../models/UserProfile.model";
import { Router } from '@angular/router';

@Component({
  selector: 'sideNavigation',
  templateUrl: './sideNavigation.component.html',
  styleUrls: ['./sideNavigation.component.css']
})
export class sideNavigation implements OnInit {
  username: string;
  isLoggedIn = false;
  userProfile: UserProfile = new UserProfile();

  constructor(private tokenStorage: TokenStorageService, private userService: UserService, private router: Router) { }

  ngOnInit() {
    this.isLoggedIn = !!this.tokenStorage.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.username = user.username;
    }

    this.userService.getUserProfile(this.username)
      .subscribe(data => {
        this.userProfile = data;

      });

  }

  routerUserProfile() {
    this.router.navigate(['/userprofile']);
  }
  routerWishList() {
    this.router.navigate(['/wishList']);
  }
  routerCurrentOrder() {
    this.router.navigate(['/currentOrderRouting']);
  }
  routerPreviousOrder() {
    this.router.navigate(['/previousOrder']);
  }


}
