import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { sideNavigation } from '../components/sideNavigation.component';


@NgModule({
  declarations: [sideNavigation],
  exports: [sideNavigation],
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot()
  ]
})
export class SideNavModule { }
